.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.heading h2 {
    margin-top: 8px;
    font-weight: 700;
}

.stateNavigate Button {
    position: absolute;
    left: 0; /* Position back button on the left */
    top: 50%;
    transform: translateY(-50%); /* Center back button vertically */
    border: none;
    background: none;
    height: 40px;
    z-index: 20;
}

.stateNavigate {
    display: flex;
  justify-content: center; /* Center the logo horizontally */
  align-items: center;
  position: relative;
  
}

.recordVideoBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 6px;
}


.backBtn img{
    height: 50px !important;
    margin-top: 10px !important;
    padding-top: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 0px !important;
}

.uploadImg {
    display: flex;
    justify-content: center;
    
}

.uploadImg .buttonText{
    line-height: 30px;
    margin-left: 5px;
}

.buttonImg img{
    background-color: rgba(255,255,255,0.3); /* White background */
    padding: 10px; /* Padding around the image */
    border-radius: 5px; /* Rounded corners */
    height: 35px; /* Height of the image */
    margin-right: 10px;
}

.uploadBtn img{
    height: 45px!important;
    /* margin-bottom: 20px; */
}

.secondHeading {
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 15px;
    font-size: 18px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    text-align: center;
    color: #45B29D;
}

.para {
    text-align: center;
}

h5 {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 600;
}

li::marker {
    background-color: #45B29D;
}

.videoOptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.uploadBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed #ccc;
    background-color: white;
    /* padding: 10px; */
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    height: 100px;
    /* Adjust height as needed */
    color: #45B29D;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top: 10px;
}

.button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.inputs{
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
}

.recordBtn {
    margin-top: 20px;
    margin-bottom: 20px;
    border: none;
    box-shadow: none;
    padding: 0px !important;
}



.dull-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.startRecording {
    margin-top: 10px;

}

/* Loader styling */
.loader {
    border: 8px solid black;
    border-radius: 50%;
    border-top: 8px solid white;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    position: fixed;
    /* Change to fixed for centering on the whole page */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    /* Ensure it appears above other elements */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


textarea {
    width: 100%;
    height: 80px;
    margin: 6px 0;
    padding: 5px;
    border: 1px solid #B5B5B5;
    border-radius: 5px;
}

textarea:focus {
    outline: none;
}

input {
    font-size: 14px;
    width: 48%;
    height: 35px;
    padding: 5px;
    border: 1px solid #B5B5B5;
    border-radius: 5px;
}

input:focus {
    outline: none;
}

.para {
    margin-top: 10px;
    line-height: 1 !important;
    margin-bottom: 5px !important;
}

.dropDown {
    height: 35px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 5px;
    border: 1px solid rgb(0, 128, 128) !important;
    border-radius: 5px !important;
    font-size: 14px;
}

.dropdown option {
    width: 30%;
}

.dropDown:focus {
    outline: none;
}

.submitbtn{
    display: flex;
    justify-content: center;
}

.submitbtn button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 55px;
    margin-bottom: 20px;
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: #043C3C;
    color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    font-size: 16px;
    font-weight: 600;

}


.showerStar{
    text-align: center;
}

ol.custom-list {
    list-style: none;
    /* Remove default list styling */
    counter-reset: custom-counter;
    /* Initialize counter */
    padding-left: 0px;
    /* Adjust padding as needed */
}

ol.custom-list li {
    counter-increment: custom-counter;
    position: relative;
    margin-bottom: 10px;
    padding-left: 35px;
}

ol.custom-list li::before {
    content: counter(custom-counter);
    background-color: #45B29D;
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
}

.video-submit-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }