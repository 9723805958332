#videoLive {
    width: 80%;
    display: flex;
    margin: auto;
    border: 1px solid black;
}

#messageContainerStyle {
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.video-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.red-dot {
    position: absolute;
    top: 15px;
    right: 70px;
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s;
}

.red-dot.active {
    opacity: 1;
    animation: blink 1s infinite;
}


.error {
    background-color: #e7dede; 
    color: #721c24; 
    padding: 10px; 
    border-radius: 5px; 
    text-align: center; 
    border: 1px solid #f5c6cb; 
    margin: 10px auto; 
    width: 80%; 
    max-width: 500px; 
  }

@media (max-width: 430px) {
    .red-dot {
        top: 12px;
        right: 35px;
        width: 10px;
        height: 10px;
    }
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }

}