.stars span{
    cursor: pointer;
    font-size: 30px;
    line-height: 1;
    color: #8c9096;
}


.selected{
    color: rgba(218, 176, 39, 0.788) !important;
}

.stars{
    margin-bottom: 12px;
    text-align: center;
}