body{
    margin: 0;
    padding: 0;
    background: url(reviews-background.jpg);
    background-size: cover;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.outer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    margin: auto;
}

.container{
  width: 100%;
  max-width: 600px;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: rgbA(255, 255, 255, 0.9);
  padding: 25px;
  border-radius: 15px;
  color: black;
  box-shadow: -20px 20px 100px 0 rgba(130, 239, 218, 0.25);
}


.intro{
  width: 100%; 
  max-width: 600px; 
  text-align: center;
  margin-top: 15px;
  padding: 10px;
}

.homeBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.homeBtn label{
  margin-right: 20px;
  margin-left: 20px;
}
.homeInnerBtn1, .homeInnerBtn2 {
  border: none;
  background: none;
  box-shadow: none; /* Remove any shadow */
  padding: 0; /* Remove any default padding */
  margin: 0; /* Remove any default margin */
}

.homeInnerBtn1 img, .homeInnerBtn2 img {
  box-shadow: none; /* Ensure no shadow on images */
}

.button{
    height: 37px;
    margin-right: 10px;
    border-radius: 7px;
    font-weight: 600;
    background-color: rgba(0, 128, 128 , 0.9);
    margin-top: 5px;
    color: white;
    border: none;
}

.button:hover{
    background-color: rgb(5, 117, 117);
    transition: all 0.2s ease-in;
    color: white;
}

.logo {
  display: flex;
  justify-content: center; /* Center the logo horizontally */
  align-items: center;
  position: relative;
}

.logo img{
    top: 0;
      height: 80px;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 10px;
  }


  @media (max-width: 450px) {
    .outer-container {
        
        width: 88%;
    }

    .homeBtn{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .container{
      padding: 10px;
    }

    .heading h2{
      font-size: 22px;
      font-weight: 700;
    }

    
  }

  @media (min-width: 451px) and (max-width: 700px) {
    .outer-container {
        
        width: 92%;
    }

    .container{
      padding: 15px;
    }
    
    
  }

/* 
  @media  (min-width: 700px) {
    .outer-container {
        
        width: 92%;
    }
    
  } */

  .headingMain{
    margin-top: 8px;
    font-weight: 700;
    text-align: center;
  }

  .secondaryHeading{
    font-weight: 500;
  }